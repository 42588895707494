body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.columns > * {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1.6;
  padding: 1.5em;
}

ul {
  margin: 0;
  padding: 0;
}

p {
  text-align: justify;
}
  
.small-column {
  flex: 1;
}

.extra-small-column {
  flex: 0.7;
}

.column-extra-small {
  flex: 0.2;
}

li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.page {
  max-width: 100%;
  font-size: 16px;
  line-height: 1.4em;
  letter-spacing: 0.001em;
  height: 440mm;
  width: 210mm;
  margin: 0 auto;
  padding: 0;
  font-family: "Benne", sans-serif;
  color: #555;
}
  
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-weight: 600;
}

@media (pointer: none), (pointer: coarse) {
  #main {
    font-size: 16px !important;
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  #main  {
    max-width: 700px !important;
    margin: 0 auto !important;
    border: 0 !important;
    display: block !important;
  }

  #main > * {
    display: block !important;
  }

  #main {
    max-width: 100% !important;
  }
}