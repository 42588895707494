#cv {  
  #main {
    padding: 0.5em;
    margin: 0 auto;
    box-sizing: border-box;
    height: 100%;
  }
  
  header img {
    border-radius: 50%;
    width: 55%;
    margin-top: 2em;
    margin-bottom: 1em;
  }
  
  a {
    color: #555;
    text-decoration: none;
  }
  
  .center {
    text-align: center;
  }
  
  header {
    text-align: center;
  }
  
  header h1 {
    margin: 0;
    padding: 0;
    font-weight: 300;
  }
  
  .fas {
    color: #62b4cf;
  }
  
  header h1 span:last-child {
    font-weight: 600;
  }
  
  section > div {
    padding: 0 1em;
  }
  
  #contact li {
    padding: 1em 0;
    border-bottom: 1px solid #62b4cf;
  }
  
  #contact li:last-child {
    border: 0;
  }
  
  #contact li span:first-child {
    width: 80%;
    border-right: 1px solid #62b4cf;
    display: inline-block;
  }
  
  #contact li span:last-child {
    width: 18%;
    display: inline-block;
    text-align: center;
  }
  
  section h2 {
    font-size: 1.1em;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    text-align: center;
    padding: 0.5em 0;
    text-transform: uppercase;
    margin-bottom: 1em;
  }
  
  #contact h2 {
    margin-bottom: 1em;
  }
  
  #knowledge li {
    display: inline-block;
    padding: 0.3em;
  }
  
  h3 {
    font-size: 1em;
  }
  
  h4 {
    margin-top: 0.5em;
    margin-bottom: 0;
    font-size: 1em;
  }
  
  .languages li {
    padding: 0.25em 0;
  }
  
  header em {
    color: #333;
  }
  
  
  #skills li {
    margin-bottom: 0.2em;
  }
  
  
  #education .columns > * {
    padding: 0.5em;
  }
  
  #education article {
    margin-bottom: 0.5em;
  }
  
  #education h3 {
    margin: 0;
  }
  #employment-history {
    h2 {
      margin-top: 0;
    }
    
    h3 {
      margin: 0.2em 0;
      padding: 0;
  
      span:first-child {
        padding-right: 0.5em;
      }
      
      span:last-child {
        padding-left: 0.5em;
        border-left: 1px solid #62b4cf;
        color: #62b4cf;
        font-size: 0.8em;
      }
    }
  }
  
  .progressbar {
    position: relative;
    width: 100%;
    height: 1.5em;
    background-color: #eee;
    padding: 0.25em;
    color: #fff;
  
    div:first-child {
      position: absolute;
      z-index: 999;
      text-transform: lowercase;
    }
  
    .inner-progressbar {
      position: absolute;
      width: 0%;
      height: 100%;
      transition: width 2s;
      top: 0;
      background-color: #62b4cf;
      margin-left: -0.5em;
    } 
  }
  
}